import styled from 'styled-components/macro'
import { motion } from 'framer-motion'

export const NavBarContainer = styled(motion.div)`
    width: 100%;
    background-color: var(--background);
    border-bottom: 1px solid var(--green-lines);
    box-shadow: 0px 3px 10px rgb(0 0 0 / 50%);
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
`

export const containerVariants = {
    initial: {
        y: -100
    },
    animate: {
        y: 0,
        transition: {
            duration: .7,
            delay: 2,
            ease: 'easeIn',
        }
    }
}

export const StickyNavBarContainer = styled(motion.div)`
    position: fixed;
    width: 100%;
    background-color: var(--background);
    border-bottom: 1px solid var(--green-lines);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    z-index: 999;

    @media all and (max-width: 600px) {
        display: none;
    }    
`

export const stickyVariants = {
    initial: {
        opacity: 1,
        top: '-100px'
    },
    animate: {
        top: '0px',
        transition: {
            duration: .5,
            type: "tween"
        }
    },
    exit: {
        opacity: 0,
        top: '-100px',
        transition: {
            duration: .2,
            type: "tween"
        }
    }
}

export const NavBarInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin-left: 4vw;
    margin-right: 1vw;
`

export const Logo = styled.div``

export const IsoLogo = styled.img`
    width: 2rem;
    position: relative;
    top: 3px;
    transtion: all .5s ease-in-out;

    @media all and (max-width: 768px) {
        width: 1.5rem;
    }
`

export const TipoLogo = styled.img`
    height: 2rem;
    transtion: all .5s ease-in-out;

    @media all and (max-width: 768px) {
        height: 1.5rem;
    }
`

export const DesktopMenuItems = styled.div`

    @media all and (max-width: 576px) {
        display: none;
    }
`

export const NavBarDetails = styled(motion.div)`
    display: flex;
    margin-right: 5rem;

    p {
        margin: 0
    }
`

export const navBarDetailsVariants = {
    initial: {
        opacity: 0,
        y: 15
    },
    animate: {
        opacity: 1,
        y: 10,
        transition: {
            delay: .5,
        }
    },
    exit: {
        opacity: 0,
        y: 15
    }
}

export const IconContainer = styled.i`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 1px;
`

export const NavMenuContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5 ease-in-out;
    cursor: pointer;

    :before {
        content: '';
        display: block;
        height: 2.5rem;
        width: 1px;
        background: var(--green-lines);
        position: relative;
        right: 10px;

        @media all and (max-width: 768px) {
            height: 2rem;
            bottom: 1px;
        }

        @media all and (max-width: 600px) {
            opacity: 0;
        }
    }
`
export const MenuItemsContainer = styled(motion.nav)``

export const menuItemsContainerVariants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            staggerChildren: .2,
            duration: .5,
        }
    },
    exit: {
        opacity: 0
    }
}

export const MenuLinks = styled(motion.a)`
    margin-right: 1.5rem;
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: .05rem;
        background: var(--black);
        dispaly: block;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transition: all .5s ease-in-out;
        transform-origin: bottom center;
    }

    &:hover:after {
        transform: scaleX(1);
        background: var(--green-lines);
    }
`

export const menuLinksVariants = {
    initial: {
        opacity: 0,
        y: 15
    },
    animate: {
        opacity: 1,
        y: 10
    },
    exit: {
        opacity: 0,
        y: 15
    }
}