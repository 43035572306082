import styled from 'styled-components/macro'
import { motion } from 'framer-motion'

export const Container = styled(motion.section)`
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    // height: 29rem;
    overflow: hidden;

    @media all and (max-width: 768px) {
        height: 18rem;
    }
`

export const imageVariants = {
    initial: {
        opacity: 0,
        width: '100vw',
        height: '100vh',
    },
    animate: {
        opacity: 1,
        width: '100%',
        height: '29rem',
        transition: {
            duration: 2,
            type: 'tween',
            delay: .5,
        }
    }
}

export const LandingImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin: 0;
    padding: 0;
    // filter: opacity(80%);
    opacity: 1;
`