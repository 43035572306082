import { MdLocationPin } from 'react-icons/md'


const LocationPin = () => {

    const style = {
        fontSize: '3rem',
        position: 'absolute',
        top: '-2.5rem',
        left: '-1.4rem',
    }

    return(
        <MdLocationPin style={style}/>
    )
}

export default LocationPin