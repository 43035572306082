import styled from 'styled-components/macro'

export const Container = styled.div`
    position: relative;
    height: 33rem;
`

export const ScrollPoint = styled.div`
    position: absolute;
    left: 0;
    top: -6rem;

    @media screen and (max-width: 768px) {
        top: -13rem;
    }
`

// export const LoaderScreen = styled.div`
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//     color: var(--background);
// `

export const GreenBack = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 25rem;
    width: 100%;
    background-color: var(--green);
`


export const CardsContainer = styled.div`
    position: relative;
    top: -1rem;

    & > div {
        padding-left: 4vw;
    }
`