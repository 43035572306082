import { useState } from 'react'
import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs'

const SwiperButtonsContainer = styled(motion.div)`
    position: absolute;
    bottom: -1.5rem;
    left: calc(50% - 4vw); //restarle el padding del container de swiper!
    transform: translateX(-50%);
    width: 7rem;
    height: 3rem;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 500;

    padding: 0 !important;

    @media all and (max-width: 768px) {
        display: none;
    }
`

const SwiperButtonsInner = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
`

const ButtonContainer = styled(motion.div)`
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    cursor: pointer;
    color: white;
`

const buttonsVariants = {
    initial: {
        opacity: 0,
        y: '10px',
    },
    animate: {
        opacity: 1,
        y: '0px',
        transition: {
            duration: .5
        }
    },
    exit: {
        opacity: 0,
        y: '10px',
        transition: {
            duration: .3
        }
    }
}

const RightSwiperButton = ({ swipe }) => {
    return (
        <ButtonContainer onClick={swipe} isRight={true} >
            <BsArrowRightCircleFill />
        </ButtonContainer>
    )
}
 
const LeftSwiperButton = ({ swipe }) => {
    return(
        <ButtonContainer onClick={swipe} isLeft={true}>
            <BsArrowLeftCircleFill />
        </ButtonContainer>
    )
}

const SwiperButtons = ({ swiperRef }) => {

    const [ containerOpacity, setContainerOpacity ] = useState(false)

    return(
        <SwiperButtonsContainer
        whileHover={{
            backgroundColor: 'var(--black)'
        }}
        containerOpacity={containerOpacity}
        variants={buttonsVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        >
            <SwiperButtonsInner>
                <LeftSwiperButton swipe={()=> swiperRef.current.swiper.slidePrev(800, false)}/>
                <RightSwiperButton swipe={()=> swiperRef.current.swiper.slideNext(800, false)}/>
            </SwiperButtonsInner>
        </SwiperButtonsContainer>
    )
}

export default SwiperButtons
