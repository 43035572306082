import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { sendMail } from '../../../utils/sendMail'
import Modal from './Modal'
import { 
    AiOutlineMessage, 
    AiOutlineCheckCircle,
} from 'react-icons/ai'
import {
    FormContainer,
    FormElement,
    InputContainer,
    Input,
    TextArea,
    Error,
    FormButton,
    FormTitle,
} from './Elements.js'

const Form = () => {

    const [ successMessageModal, setSuccesMessageModal ] = useState(false)
    const [ messageStatus, setMessageStatus ] = useState({
        sent: false,
        sending: false,
        success: false
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
            .max(20, 'No más de 20 caracteres')
            .required('Es necesario ingresar un nombre'),
            email: Yup.string()
            .email('Dirección de email inválida')
            .required('Es necesario ingresar un e-mail'),
            message: Yup.string()
            .max(50, 'No mas de 50 caracteres')
            // .required('Es necesario ingresar un mensaje')
            
        }),
        onSubmit: (values, { resetForm }) => {
            setMessageStatus({...messageStatus, sending: true})
            sendMail(values, setMessageStatus, resetForm)
            .then(() => setSuccesMessageModal(true))
        }
    })

    return (
        <FormContainer>
            <FormTitle>También podés escribirnos...</FormTitle>
            <FormElement onSubmit={formik.handleSubmit}>
                <InputContainer>
                    <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Nombre"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    />
                    {
                        formik.touched.name &&
                        formik.errors.name ?
                        <Error>{formik.errors.name}</Error> :
                        null
                    }
                </InputContainer>
                <InputContainer>
                    <Input 
                    type="email" 
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    />
                    {
                        formik.touched.email &&
                        formik.errors.email ?
                        <Error>{formik.errors.email}</Error> :
                        null
                    }
                </InputContainer>
                <InputContainer>
                    <TextArea 
                    id="message"
                    name="message"
                    placeholder="Mensaje"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    />
                </InputContainer>
                <FormButton type="submit">
                    {
                        (!messageStatus.sent && !messageStatus.sending) ? 
                        <AiOutlineMessage /> :
                        messageStatus.sending ? 
                        '...' :
                        messageStatus.sent && 
                        <AiOutlineCheckCircle />
                    }
                </FormButton>
            </FormElement>
            <Modal successMessageModal={successMessageModal} setSuccesMessageModal={setSuccesMessageModal}/>
        </FormContainer>
    );
}
 
export default Form;