import styled, { keyframes } from 'styled-components/macro'
import { motion } from 'framer-motion'

//TOP

export const SlideContainer = styled(motion.div)`
    position: relative;
    height: 30rem;
    width: 14.5rem;
    background: white;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 5px;

    p {
        margin: 0;
    }
`

export const containerVariants = {
    initial: {
        opacity: 0,
        y: 10
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            duration: .5,
            delay: 1,
        }
    },
    exit: {
        opacity: 0,
        y: '-10',
        transition: {
            duration: .3
        }
    } //En caso de que hubiera filtrado de locales por categorias
}

export const SlideTop = styled.div`
    height: 35%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--gray);
    `
    
export const SlideImg = styled.img`
    width: 70%;
    height: auto;
`

//MIDDLE

export const SlideMiddle = styled.div`
    position: relative;
    width: 85%;
    height: 55%;
    margin: 0 auto;
`

export const Class = styled.p`
    color: var(--alert-blue);
    font-weight: 600;
    font-size: 18px;
`

export const Name = styled.h3`
    margin: 0;
    font-size: 1.4rem;
    font-weight: 800;
`

export const DetailItemsContainer = styled.div`
    // min-height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`

export const DetailItem = styled.div`
    display: flex;
    margin: 4px 0;
`

export const DetailIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: .2rem;
    margin-right: 2px;
`

export const DetailText = styled.p`
    font-weight: 600;
`

export const DetailHour = styled.p`
    font-weight: 600;
    font-size: .8rem;
`

export const More = styled.p`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
`

//BOTTOM

export const SlideBottom = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--gray);
`

export const BottomAlert = styled.p`
    color: ${({isOpen, isProx}) =>
    !isProx ?
    (
        isOpen ? 
        "var(--alert-green)" : 
        "var(--alert-red)" 
    ) :
    'var(--alert-yellow)'
    };
    font-weight: 700;
    z-index: ${({isProx}) => isProx && '600'};
    
    svg {
        // display: inline-flex;
        // justify-content: center;
        // align-items: center;
        position: relative;
        top: 4px;
        font-size: 1.2em;
    }
`


//MODAL

export const SlideModalContainer = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // filter: blur(5px);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 800;
    cursor: pointer;
`

export const SlideModal = styled(motion.div)`
    position: absolute;
    width: 40%;
    height: 10rem;
    background-color: var(--background);
`

//COVER

export const SlideCover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .8;
    background-color: white;
    border-radius: 20px;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
`

//SKELETON
const load = keyframes`
    from { left: -100%}
    to { left: 100%;}
`

export const SkeletonContainer = styled(motion.div)`
    position: relative;
    height: 30rem;
    width: 14.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 5px;
    overflow: hidden;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -100%;
        height: 100%;
        width: 100%;
        background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
        animation: ${load} 1.6s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    p {
        margin: 0;
    }

`