import styled from 'styled-components/macro'
import Location from './Location/Location'
import Form from './Form/Form'

const Container = styled.div`
    position: relative;
    width: 90%;
    margin: 3rem auto;  
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 1024px) {
        flex-direction: column;
    }  
`

const ScrollPoint = styled.div`
    position: absolute;
    left: 0;
    top: -5.5rem;

    @media screen and (max-width: 768px) {
        top: -13rem;
    }
`

const Contact = () => {
    return(
        <Container>
            <ScrollPoint id="contacto-ubicacion" />
            <Location />
            <Form />
        </Container>
    )
}

export default Contact