import styled from 'styled-components/macro'
import { motion } from 'framer-motion'

export const Menu = styled(motion.div)`
    display: flex;
    flex-direction: column;
    cursor: pointer;
`
    
export const Svg = styled(motion.svg)`
    margin-bottom: ${({bottom, opened}) => opened ? bottom : "2px"}; //Para centrar la X
    width: ${({opened}) => opened ? "20px" : "25px"};
    ${({opacity}) => opacity ? "opacity: 0" : "opacity: 1"};
    top: ${({opened, top}) => opened ? top : "0"};
    transform: ${({opened, rotation}) => opened ? `rotate(${rotation})` : "rotate(0)"};
    transition: all .2s ease-in-out;
    position: relative;
`

export const Path = styled(motion.path)`
    stroke-linecap: round;
    stroke-width: 3;
    stroke: var(--black);
`

export const pathVariants = {
    initial: {
        strokeWidth: 1.5
    },
    animate: {
        strokeWidth: 2
    },
    exit: {
        strokeWidth: 1.5
    }
}
