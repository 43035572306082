import { useState, useEffect } from 'react'
import { getFirestore } from '../../firebase/firebase'
import ShopsSwiper from './ShopsSwiper'

const SwiperContainer = () => {

    const db = getFirestore()
    const [ shopsData, setShopsData] = useState([])

    useEffect(() => {
        
        db.collection('locales').get()
        .then(shops => {
            let array = []
            shops.forEach(shop => array.push(shop.data()))
            setShopsData(array)
        })
    }, [])

    return <ShopsSwiper data={shopsData} />
}

export default SwiperContainer
