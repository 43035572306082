import { useState, useEffect } from 'react'
import { GrMapLocation } from 'react-icons/gr'
import { BsPhone } from 'react-icons/bs'
import GoogleMapReact from 'google-map-react'
import LocationPin from './LocationPin'
import { getFirestore } from '../../../firebase/firebase'
import {  
    Bottom, 
    BottomSection,
    Detail,
    Container, 
    Icon, 
    Map, 
    Top } from "./Elements";

const Location = () => {

    const location = {lat: -34.577528, lng: -58.696735}

    const db = getFirestore()
    const [ apiKey, setApiKey ] = useState('')

    useEffect(() => {
        db.collection('api-keys').doc('google-map-clave-api').get()
        .then(doc => {
            if (doc.exists) {
                console.log("Document data:", doc.data().key);
                setApiKey(doc.data().key)
            } else {
                console.log("No such document!");
            }
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        });

    }, [])

    return (
        <Container>
            <Top>
                <Map>
                    {
                        apiKey.length != 0 &&
                        <GoogleMapReact
                        bootstrapURLKeys={{key: "AIzaSyC_3CAWukuh2eb3KeN7GWl9nr_9k7MyuTs"}}
                        defaultCenter={location}
                        defaultZoom={15}
                        >
                            <LocationPin 
                                lat={location.lat}
                                lng={location.lng}
                            />
                        </GoogleMapReact>
                    }
                </Map>
            </Top>
            <Bottom>
                <BottomSection>
                    <Icon><GrMapLocation /></Icon>
                    <Detail>
                        Encontranos en Mayor Irusta 2921/45, Bella Vista.
                    </Detail>
                </BottomSection>
                <BottomSection>
                    <Icon><BsPhone /></Icon>
                    <Detail>
                        113-322-2192
                    </Detail>
                </BottomSection>
            </Bottom>
        </Container>
    );
}
 
export default Location;