import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import isoLogo from '../../logo/isologo.png'
import tipoLogo from '../../logo/tipologo.png'

const Container = styled.div`
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
    border-top: 1px solid var(--green-lines);

    @media all and (max-width: 600px) {
        padding-bottom: 4rem;
    }
`

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Logo = styled.div`
    display: flex;
`

export const IsoLogo = styled.img`
    width: 2rem;
    height: auto;
    position: relative;
    top: 0;
`

export const TipoLogo = styled.img`
    height: 3rem;
    width: auto;
`

export const Copy = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 1rem;
`


const Footer = () => {

    const [ year, setYear ] = useState()

    useEffect(() => {

        const now = DateTime.now()

        setYear(now.year)

    }, [])

    return (
        <Container>
            <LogoContainer>
                <Logo>
                    <IsoLogo src={isoLogo} alt="logo" />
                    <TipoLogo src={tipoLogo} alt="logo" />
                </Logo>
            </LogoContainer>
            <Copy>
                Los Berros&copy; {year && year} - by Go
            </Copy>
        </Container>
    )
}
 
export default Footer;