import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const app = firebase.initializeApp({
    apiKey: "AIzaSyBJEJ-wiXZGhHOymXS8f4OZ-ZI7ChImX1M",
    authDomain: "paseo-berros.firebaseapp.com",
    projectId: "paseo-berros",
    storageBucket: "paseo-berros.appspot.com",
    messagingSenderId: "807915041540",
    appId: "1:807915041540:web:3147cc14a4255cfe8f5651",
    measurementId: "G-6Q58BQ8NVJ"
})

export function getFirebase() {
    return app
}

export function getFirestore() {
    return firebase.firestore(app)
}