import styled from 'styled-components'
import NavBar from './components/NavBar/NavBar'
import Landing from './components/Landing/Landing'
import SwiperContainer from './components/Swiper/SwiperContainer'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'

const AppContainer = styled.div`
  max-width: 1860px;
  margin: 0 auto;
`

function App() {
  return (
    <AppContainer>
      <NavBar />
      <Landing />
      <SwiperContainer />
      <Contact />
      <Footer />
    </AppContainer>
  );
}

export default App;
