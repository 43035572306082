import { useState, useRef, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import SwiperCore, { Pagination } from 'swiper'
import { DateTime } from 'luxon'
import { useInterval } from '../../utils/useInterval'
import Slide from './Slide/Slide'
import './Slide/slides.css' //styles para override swiper-container
import SwiperButtons from './SwiperButtons'
import SkeletonCard from './Slide/SkeletonCard'
import { 
    CardsContainer,
    Container,
    ScrollPoint,
    GreenBack,
} from "./Elements"

SwiperCore.use([Pagination])

const ShopsSwiper = ({ data }) => {

    const swiperRef = useRef()
    const [ isHovering, setHovering ] = useState(false)
    const [ actualMinute, setActualMinute ] = useState(DateTime.now().setZone('America/Buenos_Aires').minute)
    const [ actualHour, setActualHour ] = useState(DateTime.now().setZone('America/Buenos_Aires').hour)
    const [ actualDay, setActualDay ] = useState(0)
    const [ skeletons, setSkeletons ] = useState([])

    useInterval(() => {
        setActualMinute(DateTime.now().setZone('America/Buenos_Aires').minute)
        setActualHour(DateTime.now().setZone('America/Buenos_Aires').hour) 
        setActualDay(DateTime.now().setZone('America/Buenos_Aires').weekday)
        //El problema era que hasta que no cambie la hora, el State va a ser igual y no va a haber rerender
        // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
    }, 1000)

    useEffect(() => {
        setSkeletons(loopSkeletons)
    }, [])

    const loopSkeletons = () => {
        let content = []
        for(let i=0; i < 5; i++) {
            content.push(i)
        }
        return content
    }

    return(
        <Container>
            <ScrollPoint id="locales-oficinas" />
            <GreenBack>
                <CardsContainer
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    <Swiper
                    ref={swiperRef}
                    freeMode={true}
                    grabCursor={true}
                    slidesPerView="auto"
                    >
                        {
                            data.length ?
                            <>
                            {
                                data.map((shop, index) => {
                                    return(
                                        <SwiperSlide key={index} className='shop-slide'>
                                            <Slide data={shop} hour={actualHour} day={actualDay} minute={actualMinute}/>
                                        </SwiperSlide>
                                    )
                                })
                            }
                            </> :
                            <>
                            {
                                skeletons.map((skeleton, index) => {
                                    return(
                                        <SwiperSlide key={index} className='shop-slide'>
                                            <SkeletonCard />
                                        </SwiperSlide>
                                    )
                                })
                            }
                            </>
                        }
                    </Swiper>
                    <AnimatePresence>   
                        {
                            isHovering && 
                            <SwiperButtons swiperRef={swiperRef} />
                        }
                    </AnimatePresence>
                </CardsContainer>

            
            
            
            
            
            
            
            {/* {
                data.length &&
                <CardsContainer
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    <Swiper
                    ref={swiperRef}
                    freeMode={true}
                    grabCursor={true}
                    slidesPerView="auto"
                    >
                        {
                            data.map((shop, index) => {
                                return(
                                    <SwiperSlide key={index} className='shop-slide'>
                                        <Slide data={shop} hour={actualHour} day={actualDay} minute={actualMinute}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    <AnimatePresence>   
                        {
                            isHovering && 
                            <SwiperButtons swiperRef={swiperRef} />
                        }
                    </AnimatePresence>
                </CardsContainer>
            } */}
            </GreenBack>
        </Container>
    )
}

export default ShopsSwiper