import styled from 'styled-components/macro'
import { motion } from 'framer-motion'

export const MobileMenuContainer = styled(motion.div)`
    display: none;
    width: 100%;
    transition: all .2s ease-in-out;
    height: ${({isOpen}) => isOpen ? '10rem' : '0'};
    
    @media all and (max-width: 600px) {
        display: block;
    }
`
    
export const MobileMenuItemsContainer = styled(motion.nav)`
    height: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    a {
        width: 100%;
        margin: 0;
        text-align: center;
    }
`