import { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import isoLogo from '../../logo/isologo.png'
import tipoLogo from '../../logo/tipologo.png'
import NavMenuIcon from './NavMenuIcon/NavMenuIcon'
import {
    NavBarContainer,
    NavBarInner,
    Logo,
    IsoLogo,
    TipoLogo,
    DesktopMenuItems,
    MenuItemsContainer,
    NavMenuContainer,
    menuItemsContainerVariants,
    MenuLinks,
    menuLinksVariants,
    StickyNavBarContainer,
    stickyVariants,
    containerVariants,
} from './NavBarElements'
import {
    MobileMenuContainer,
    MobileMenuItemsContainer,
} from './MobileElements'

const NavBar = () => {

    const [ fixNav, setFixNav ] = useState()
    const [ openMenu, setOpenMenu ] = useState(false)

    const handleFixNav = () => {

        window.scrollY > 200 ? setFixNav(true) : setFixNav(false)

    }

    useEffect(() => {
        
        const cleanup = () => window.addEventListener("scroll", handleFixNav)

        return cleanup()

    }, [])

    return(
        <AnimatePresence>
            <NavBarContainer
            variants={containerVariants}
            initial="initial"
            animate="animate"
            >
                <NavBarInner>
                    <Logo>
                        <IsoLogo src={isoLogo} alt="logo" />
                        <TipoLogo src={tipoLogo} alt="logo" />
                    </Logo>
                    <NavMenuContainer 
                    isOpen={openMenu ? 1 : 0} 
                    onClick={() => setOpenMenu(!openMenu)}
                    >
                        <DesktopMenuItems>
                            <AnimatePresence>
                                {
                                    openMenu &&
                                    <MenuItemsContainer
                                    variants={menuItemsContainerVariants}
                                    initial="initial" 
                                    animate="animate"
                                    exit="exit"
                                    isOpen={openMenu}
                                    >
                                        <MenuLinks 
                                        variants={menuLinksVariants}
                                        href="#locales-oficinas"
                                        onClick={() => setOpenMenu(!openMenu)}
                                        >
                                            Locales y Oficinas
                                        </MenuLinks>
                                        <MenuLinks 
                                        variants={menuLinksVariants}
                                        href="#contacto-ubicacion"
                                        onClick={() => setOpenMenu(!openMenu)}
                                        >
                                            Ubicación
                                        </MenuLinks>
                                        <MenuLinks 
                                        variants={menuLinksVariants}
                                        href="#contacto-ubicacion"
                                        onClick={() => setOpenMenu(!openMenu)}
                                        >
                                            Contacto
                                        </MenuLinks>
                                    </MenuItemsContainer>
                                }
                            </AnimatePresence>
                        </DesktopMenuItems>
                        <NavMenuIcon openMenu={openMenu} />
                    </NavMenuContainer>
                </NavBarInner>

                {/* Sticky.............................................. */}
                <AnimatePresence>
                    {
                        fixNav &&
                        <StickyNavBarContainer 
                        variants={stickyVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        >
                            <NavBarInner>
                                <Logo>
                                    <IsoLogo src={isoLogo} alt="logo" />
                                    <TipoLogo src={tipoLogo} alt="logo" />
                                </Logo>
                                <NavMenuContainer 
                                isOpen={openMenu ? 1 : 0} 
                                onClick={() => setOpenMenu(!openMenu)}
                                >
                                    <DesktopMenuItems>
                                        <AnimatePresence>
                                        {
                                            openMenu &&
                                            <MenuItemsContainer
                                            variants={menuItemsContainerVariants}
                                            initial="initial" 
                                            animate="animate"
                                            exit="exit"
                                            isOpen={openMenu}
                                            >
                                                <MenuLinks 
                                                variants={menuLinksVariants}
                                                href="#locales-oficinas"
                                                onClick={() => setOpenMenu(!openMenu)}
                                                >
                                                    Locales y Oficinas
                                                </MenuLinks>
                                                <MenuLinks 
                                                variants={menuLinksVariants}
                                                href="#contacto-ubicacion"
                                                onClick={() => setOpenMenu(!openMenu)}
                                                >
                                                    Ubicación
                                                </MenuLinks>
                                                <MenuLinks 
                                                variants={menuLinksVariants}
                                                href="#contacto-ubicacion"
                                                onClick={() => setOpenMenu(!openMenu)}
                                                >
                                                    Contacto
                                                </MenuLinks>
                                            </MenuItemsContainer>
                                        }
                                        </AnimatePresence>
                                    </DesktopMenuItems>
                                    <NavMenuIcon openMenu={openMenu} />
                                </NavMenuContainer>
                            </NavBarInner>
                        </StickyNavBarContainer>
                    }
                </AnimatePresence>


                {/* Mobile Menu .............................................. */}
                <AnimatePresence>
                    <MobileMenuContainer isOpen={openMenu}>
                        {
                            openMenu &&
                            <MobileMenuItemsContainer
                            variants={menuItemsContainerVariants}
                            initial="initial" 
                            animate="animate"
                            exit="exit"
                            isOpen={openMenu}
                            >
                                <MenuLinks 
                                variants={menuLinksVariants}
                                href="#locales-oficinas"
                                onClick={() => setOpenMenu(!openMenu)}
                                >
                                    Locales y Oficinas
                                </MenuLinks>
                                <MenuLinks 
                                variants={menuLinksVariants}
                                href="#contacto-ubicacion"
                                onClick={() => setOpenMenu(!openMenu)}
                                >
                                    Ubicación
                                </MenuLinks>
                                <MenuLinks 
                                variants={menuLinksVariants}
                                href="#contacto-ubicacion"
                                onClick={() => setOpenMenu(!openMenu)}
                                >
                                    Contacto
                                </MenuLinks>
                            </MobileMenuItemsContainer>
                        }
                    </MobileMenuContainer>
                </AnimatePresence>
            </NavBarContainer>
        </AnimatePresence>
    )
}

export default NavBar