import { AnimatePresence } from 'framer-motion'
import { 
    AiOutlineCloseCircle 
} from 'react-icons/ai'
import {
    ModalContainer,
    ModalInner,
    innerVariants,
    containerVariants,
    CloseIcon
} from './Elements.js'

const Modal = ({ successMessageModal, setSuccesMessageModal }) => {
    return (  
        <AnimatePresence>
        {
            successMessageModal &&
            <ModalContainer
            variants={containerVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            onClick={() => setSuccesMessageModal(false)}
            >
                <ModalInner
                variants={innerVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                >
                    <p>Hemos recibido su mensaje.</p>
                    <p>¡Gracias por escribirnos!</p>
                    <CloseIcon>
                        <AiOutlineCloseCircle onClick={() => setSuccesMessageModal(false)} />
                    </CloseIcon>
                </ModalInner>
            </ModalContainer>
            
        }
</AnimatePresence>
    )
}
 
export default Modal