import styled from 'styled-components/macro'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 55%;
    background: var(--green);
    border-radius: 25px;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;

    @media all and (max-width: 1024px) {
        width: 100%;
    }
`

export const Top = styled.div``

export const Map = styled.div`
    width: 100%;
    height: 25rem;
    border-radius: 25px 25px 0 0;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    @media all and (max-width: 1024px) {
        height: 20rem;
        // object-fit: cover;
    }
`

export const Bottom = styled.div`
    padding-top: .5rem;
`

export const BottomSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: .5rem;

    @media all and (max-width: 600px) {
        flex-direction: column;
    }
`

export const Icon = styled.div`
    margin-right: 1rem;
    font-size: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 1024px) {
        font-size: 3.5vw;
    }

    @media all and (max-width: 600px) {
        margin-top: 2vw;
        font-size: 1.5rem;
    }
`

export const Detail = styled.h3`

    margin: .3rem;

    @media all and (max-width: 600px) {
        margin: 1.5vw;
        text-align: center;
    }
`