import styled from 'styled-components/macro'
import { motion } from 'framer-motion'

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;

    @media all and (max-width: 1024px) {
        width: 100%;
        margin-top: 1.5rem;
    }

    @media all and (max-width: 460px) {
        width: 90%;
        margin: 0 auto;
        margin-top: 1rem;
    }
`

export const FormTitle = styled.h3`
    margin-bottom: 2rem;
`

export const FormElement = styled.form`
    @media all and (max-width: 460px) {
        width: 100%;
    }
`

export const InputContainer = styled.div`
    position: relative;
    height: 3rem;
    margin-bottom: 1rem;
`

export const Input = styled.input`
    border: none;
    border-bottom: 2px solid var(--black);
    background: transparent;
    width: 20rem;
    font-size: 18px;
    outline: none;

    &::placeholder {
        font-size: 18px;
        font-family: 'Baloo 2', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }

    @media all and (max-width: 460px) {
        width: 100%;
    }
`

export const TextArea = styled.textarea`
    border: none;
    border-bottom: 2px solid var(--black);
    background: transparent;
    width: 20rem;
    font-size: 18px;
    outline: none;
    font-family: 'Baloo 2', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    &::placeholder {
        font-size: 18px;
        font-family: 'Baloo 2', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }

    @media all and (max-width: 460px) {
        width: 100%;
    }
`

export const FormButton = styled.button`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 4rem;
    height: 4rem;
    margin-top: 4rem;
    border-radius: 50%;
    border: none;
    background-color: var(--alert-blue);
    font-family: 'Baloo 2', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: white;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
        pointer-events: none;
        font-size: 35px;
    }
`

export const Error = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--alert-red);
`

export const containerVariants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    }
}

export const ModalContainer = styled(motion.div)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`

export const innerVariants = {
    initial: {
        opacity: 0,
        y: '30px'
    },
    animate: {
        opacity: 1,
        y: '0px',
        transition: {
            type: 'tween'
        }
    },
    exit: {
        opacity: 0, 
        y: '-30px',
        transition: {
            type: 'tween'
        }
    }
}

export const ModalInner = styled(motion.div)`
    position: relative;
    width: 25%;
    padding: 2rem;
    background-color: var(--background);
    border-radius: 10px;

    p {
        margin: 0;
        font-size: 18px;
    }

    @media all and (max-width: 768px) {
        width: 40%;
    }
`

export const CloseIcon = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;

    svg {
        font-size: 2rem;
    }
`