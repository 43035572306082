import { Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
    Autoplay,Pagination
  } from 'swiper/core';
import 'swiper/swiper-bundle.css'
import './styles.css'
import { 
    Container,
    imageVariants,
    LandingImage,
} from "../Landing/Elements"

SwiperCore.use([Autoplay,Pagination]);

const Landing = () => {

    return(
        <Container
        variants={imageVariants}
        initial='initial'
        animate='animate'
        >
            <Swiper 
            pagination={true}
            // autoplay={{delay: 7500, disableOnInteraction: true}}
            // loop={true}
            modules={[Pagination]}
            className='landing-swiper'
            >
                <SwiperSlide className='landing-slide'>
                    <LandingImage src={'media/paseo-landing.jpeg'}/>
                </SwiperSlide>
                <SwiperSlide className='landing-slide'>
                    <LandingImage src={'media/paseo-landing1.jpeg'}/>
                </SwiperSlide>
                <SwiperSlide className='landing-slide'>
                    <LandingImage src={'media/paseo-landing2.jpeg'}/>
                </SwiperSlide>
            </Swiper>
        </Container>
    )
}

export default Landing