import React from 'react'
import {
  SkeletonContainer,
  containerVariants, 
  SlideMiddle, 
  SlideTop,
  SlideBottom,
} from './Elements'
import './slides.css'

const SkeletonCard = () => {
  return (
      <SkeletonContainer>
        <SlideTop>
        </SlideTop>
        <SlideMiddle>
        </SlideMiddle>
        <SlideBottom>
        </SlideBottom>
      </SkeletonContainer>
  )
}

export default SkeletonCard