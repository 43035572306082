export const checkShopState = (
    minute,
    hour,
    day,
    data,
    setShopState,
    ) => {

    const timeInMinutes = (hour * 60) + minute
    const array = [ 
        data.open, 
        data.closeMid, 
        data.openMid, 
        data.close, 
        data.openSab, 
        data.closeMidSab, 
        data.openMidSab, 
        data.closeSab, 
        data.openDom, 
        data.closeDom
    ]
    const openingInMinutes = [] // guardar horario formateado en minutos para cada evento (apertura, cierre, etc)

    array.forEach(shopHour => {

        if(shopHour !== null && shopHour.includes(":")) {

            let minuteStart = shopHour.indexOf(":")
            let hoursPart = shopHour.slice(0, minuteStart)
            let minutesPart = shopHour.slice(minuteStart + 1)
            let thisHourInMinutes = (hoursPart * 60) + parseInt(minutesPart)
            openingInMinutes.push(thisHourInMinutes)

        } else if( shopHour === null) {
            return openingInMinutes.push(null)
        } 
        else {
            return openingInMinutes.push(shopHour * 60)
        }
    })

    if(day === 7) {

        if(data.dom === true) { // solo para shops que abren los domingos (abren todos de corrido, por eso 1 condicion)

            if(timeInMinutes >= openingInMinutes[7] && timeInMinutes < openingInMinutes[8]) {

                setShopState(true)

            } else {

                setShopState(false)

            }
        } else {
            
            setShopState(false)

        }

    } else if(day === 6) {

        if(data.sab === true) { // solo para shops que abren los sabados

            if(openingInMinutes[1] !== null) { //.. que cierra al mediodia

                if(
                    (timeInMinutes >= openingInMinutes[4] && timeInMinutes < openingInMinutes[5]) ||
                    (timeInMinutes >= openingInMinutes[6] && timeInMinutes < openingInMinutes[7]) 
                ) {
    
                    setShopState(true)
    
                } else {
    
                    setShopState(false)
    
                }
    
            } else { //.. no cierra al mediodia
    
                if(timeInMinutes >= openingInMinutes[4] && timeInMinutes < openingInMinutes[7]) {
    
                    setShopState(true)
    
                } else {
    
                    setShopState(false)
    
                }
            }

        } else {
            
            setShopState(false)

        } 

    } else { // .. es lunes a viernes

        if(openingInMinutes[1] !== null) { //.. que cierra al mediodia

            if(
                (timeInMinutes >= openingInMinutes[0] && timeInMinutes < openingInMinutes[1]) ||
                (timeInMinutes >= openingInMinutes[2] && timeInMinutes < openingInMinutes[3]) 
            ) {

                setShopState(true)

            } else {

                setShopState(false)

            }

        } else { //.. no cierra al mediodia

            if(timeInMinutes >= openingInMinutes[0] && timeInMinutes < openingInMinutes[3]) {

                setShopState(true)

            } else {

                setShopState(false)

            }
        }
    }
}