import {
    Menu,
    Svg,
    Path,
    pathVariants
} from './Elements'

const NavMenuIcon = ({ openMenu }) => {

    return(
        <Menu
        whileHover="animate"
        initial="initial"
        exit="exit"
        opened={openMenu}
        >
            <Svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 34 4" 
            fill="none" 
            opened={openMenu} 
            top={"2.5px"}
            rotation={"40deg"}
            bottom={"0px"}
            >
                <Path
                variants={pathVariants}
                d="M2 2H32"
                />
            </Svg>
            <Svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 34 4" 
            fill="none" 
            opened={openMenu} 
            opacity={openMenu}
            top={"0"}
            rotation={"15deg"}
            bottom={"2px"}
            >
                <Path
                variants={pathVariants}
                d="M2 2H32"
                />
            </Svg>
            <Svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 34 4" 
            fill="none" 
            opened={openMenu}
            top={"-4px"}
            rotation={"-40deg"}
            bottom={"0px"}
            >
                <Path
                variants={pathVariants}
                d="M2 2H32"
                />
            </Svg>
        </Menu>
    )
}

export default NavMenuIcon