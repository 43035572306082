import { useState, useEffect } from 'react'
import { checkShopState } from '../../../utils/checkShopState'
import { AiOutlineInstagram } from 'react-icons/ai'
import { BiTimeFive } from 'react-icons/bi'
import { BsPhone } from 'react-icons/bs'
import { IoLocationOutline } from 'react-icons/io5'
import './slides.css'
// import Modal from './Modal'
import { 
    BottomAlert,
    Class,
    DetailItemsContainer, 
    DetailIcon,
    DetailItem,
    DetailText,
    DetailHour,
    Name,
    More,
    SlideBottom, 
    SlideContainer,
    containerVariants, 
    SlideImg, 
    SlideMiddle, 
    SlideTop,
    SlideCover,
    CoverText,
} from "./Elements"

const Slide = ({ data, hour, day, minute }) => {

    const [ shopState, setShopState ] = useState(false)
    const [ isModalOpen, setModalOpen] = useState(false)
    
    useEffect(() => {
        checkShopState(minute, hour, day, data, setShopState)
    }, [minute])

    return(
        <SlideContainer
        // variants={containerVariants}
        // initial="initial"
        // animate="animate"
        // exit="exit"
        >
            <SlideTop>
                <SlideImg src={data.imgUrl} />
            </SlideTop>
            <SlideMiddle>
                <Class>{data.class}</Class>
                <Name>{data.name}</Name>
                <DetailItemsContainer>
                    <DetailItem>
                        <DetailIcon><BiTimeFive /></DetailIcon>
                        <DetailHour>
                            Lun. a Vie.
                            {
                                data.openMid !== null ?
                                ` ${data.open} a ${data.closeMid}hs / ${data.openMid} a ${data.close}hs ` :
                                ` ${data.open} a ${data.close}hs` 
                            }  
                            <br />
                            Sab.
                            {
                                data.sab === true ?
                                    data.closeMidSab === null ?
                                    ` ${data.openSab} a ${data.closeSab}hs` :
                                    ` ${data.openSab} a ${data.closeMidSab} / ${data.openMidSab} a ${data.closeSab}hs`
                                    :
                                    ` Cerrado`
                            }
                            <br />
                            Dom.
                            {
                                data.dom === true ?
                                ` ${data.openDom} a ${data.closeDom}hs` :
                                ` Cerrado`
                            }
                        </DetailHour> 
                        {/* agregar en DetailText un condicional> si horario sab. dom. entre parentesis */}
                    </DetailItem>
                    <DetailItem>
                        <DetailIcon><IoLocationOutline /></DetailIcon>
                        <DetailText>{data.location}</DetailText>
                    </DetailItem>
                    <DetailItem>
                        <DetailIcon><BsPhone /></DetailIcon>
                        <DetailText>{data.contact}</DetailText>
                    </DetailItem>
                    <DetailItem>
                        <DetailIcon><AiOutlineInstagram /></DetailIcon>
                        <DetailText>{data.social}</DetailText>
                    </DetailItem>
                </DetailItemsContainer>
                <More onClick={() => setModalOpen(true)} >
                    {
                        data.more && 'Ver más'
                    }
                </More>
            </SlideMiddle>
            <SlideBottom>
                <BottomAlert isOpen={shopState} isProx={data.prox}>
                    <span><BiTimeFive /> </span>
                    {
                        !data.prox ?
                        (
                            shopState ?
                            'Abierto' : 
                            'Cerrado'
                        ) :
                        'Proximamente'
                    }
                </BottomAlert>
            </SlideBottom>
            {
                data.prox && <SlideCover />
            }
        </SlideContainer>
    )
}

export default Slide