import emailjs from 'emailjs-com'

export const sendMail = (data, setMessageStatus, resetForm) => {

    return new Promise((resolve, reject) => {

        const resetSetMessageStatus = () => {
    
            setTimeout(() => {
    
                setMessageStatus({
                    sent: false,
                    sending: false,
                    success: false
                })
    
            }, 1500)
        }
    
    
        //ENVIAR MAIL
        emailjs.send('service_qrqskzh','template_c7xbvra', data, 'user_EIay6JjY379bvcnktAOIF')
        .then(() => {
    
            setMessageStatus({
                sent: true,
                sending: false,
                success: true
            })
    
            resetForm() //Esta funcion es nativa de Formik, la traigo nomas
            resetSetMessageStatus()
            resolve('Ok')
            console.log('sent!')
        })
        .catch(err => {
            reject('Bad')
            console.log(err)
        })
    })
    
}